import React, { useContext } from "react";
import { MainContext } from "../App";

function Categories() {
	const { setCategory, setPopupVisible, menu } = useContext(MainContext);

	const handleClick = async (category) => {
		setCategory(category);
		setPopupVisible(false);
	};

	return (
		<div className="popup">
			<div className="container">
				<header>
					<div className="buttons-group">
						<button
							className="btn btn-tertiary btn-square"
							onClick={() => setPopupVisible(false)}
						>
							<i className="fas fa-times"></i>
						</button>
						<div>Seleziona la categoria</div>
					</div>
				</header>
				<div className="content">
					{menu &&
						menu.map((category, i) => (
							<button
								onClick={() => handleClick(category)}
								key={i}
								className="list-button"
							>
								{category.name}
							</button>
						))}
				</div>
			</div>
		</div>
	);
}

export default Categories;
